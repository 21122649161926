import { IContextMenuData } from "./ContextMenuSlot";
import {EditorEventMgr} from "../../Game/Event/EditorEventMgr";


export class ContextMenuManager {
    /**
     * 打开右键菜单
     */
    public static showContextMenu(menuData: IContextMenuData) {
        EditorEventMgr.Instance.emitEvent("ShowContextMenu", cb => cb(menuData));
    }
}