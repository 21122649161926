import { EditorEventMgr } from "../../Game/Event/EditorEventMgr";

export class LogManager {
    public static get Instance(): LogManager {
        if (this.instance == null) {
            this.instance = new LogManager();
        }
        return this.instance;
    }
    private static instance: LogManager;
    private _logList: any[] = [];
    public upDateTextNodeFun(text: string) {
        if (text != "") {
            if (this._logList.length > 100) {
                let logObj=this._logList.shift();//从数组开头 开始删
                logObj=null;
            }
            let logObj={};
            logObj["title"]=text;
            this._logList.push(logObj);
            EditorEventMgr.Instance.emitEvent("OnConsoleLog", cb => cb(this._logList));
        }
    }
}