export class EditorObjectTags {
    /**
     * 拖动轴
     */
    public static editorAxisTag = "editorAxis";
    /**
     * 编辑器场景中的ui
     */
    public static editorUiTag = "editorUi";
    /**
     * 游戏内的ui
     */
    public static uiTag = "ui";
    /**
     * 在节点树中隐藏
     */
    public static hideInTreeTag = "hideInTree";
    /**
     * 场景主相机
     */
    public static mainCamera = "mainCamera";
    /**
     * 编辑器相机
     */
    public static editorCamera = "editorCamera";
}