import { setTimeout } from "timers/promises";
import { IComponentData } from "../../common/inspector/components/Component";
import { EditorApplication } from "../EditorApplication";
import { AssetBundleFileType, ResMgr } from "../ResMgr";
import { ValueType } from "../ValueType";
import texture = m4m.framework.texture;
import sprite = m4m.framework.sprite;
import vector2 = m4m.math.vector2;
import {AssetReference, AtlasReference} from "../Asset/AssetReference";
import I2DComponent = m4m.framework.I2DComponent;

export class ImageAttributeDataMgr {
    //Image组件属性变化
    public static getImage2DData(node: I2DComponent): IComponentData {
        let selection = EditorApplication.Instance.selection;
        let assetmgr = m4m.framework.sceneMgr.app.getAssetMgr();
        let image = node as m4m.framework.image2D;

        // global["test1111"] = () => {
        //     //     let sprite = assetmgr.getDefaultSprite("grid_sprite") as m4m.framework.sprite;
        //     //     if(!sprite)
        //     //     {
        //     //         sprite = assetmgr.getAssetByName("grid_sprite") as m4m.framework.sprite;
        //     //     }

        //     //     image.sprite=sprite;
        //     //     image.transform.markDirty();

        //     // image.imageBorder.l = 20;
        //     // image.transform.markDirty();

        //     image.fillMethod=m4m.framework.FillMethod.Radial_90;
        //     image.transform.markDirty();

        //     // image.fillAmmount=0.6;
        //     // image.transform.markDirty();
        // }
        //
        let onSelectFun: Function = null;

        //-----------------------
        //设置图片数据
        let spriteRefresh: Function = null;
        let setSprite = selection.addPropertyListener(image, "sprite", ValueType.object, (value) => {
            if (spriteRefresh) {
                spriteRefresh(value);
            }
        });
        //-----------------------

        //设置图片颜色
        let colorRefresh: Function = null;
        let setColor = selection.addPropertyListener(image, "color", ValueType.string, (value) => {
            if (colorRefresh) {
                colorRefresh(value);
            }
        });

        let ImageTypeArr = [];
        ImageTypeArr.push({label: "Simple", value: m4m.framework.ImageType.Simple});
        ImageTypeArr.push({label: "Sliced", value: m4m.framework.ImageType.Sliced});//九宫
        ImageTypeArr.push({label: "Tiled", value: m4m.framework.ImageType.Tiled});//平铺
        ImageTypeArr.push({label: "Filled", value: m4m.framework.ImageType.Filled});//填充
        //设置贴图的显示方式
        let imageTypeRefresh: Function = null;
        let setImageType = selection.addPropertyListener(image, "imageType", ValueType.string, (value) => {
            if (imageTypeRefresh) {
                imageTypeRefresh(value);
            }
        });

        //设置九宫
        let imageBorderRefreshL: Function = null;
        let imageBorderRefreshT: Function = null;
        let imageBorderRefreshR: Function = null;
        let imageBorderRefreshB: Function = null;
        let setImageBorder = selection.addPropertyListener(image, "imageBorder", ValueType.border, (value) => {
            // console.error(value.l);
            if (imageBorderRefreshL) {
                imageBorderRefreshL(value.l);
            }
            if (imageBorderRefreshT) {
                imageBorderRefreshT(value.t);
            }
            if (imageBorderRefreshR) {
                imageBorderRefreshR(value.r);
            }
            if (imageBorderRefreshB) {
                imageBorderRefreshB(value.b);
            }
        });

        let FillMethodArr = [];
        FillMethodArr.push({label: "Horizontal", value: m4m.framework.FillMethod.Horizontal});
        FillMethodArr.push({label: "Vertical", value: m4m.framework.FillMethod.Vertical});//
        FillMethodArr.push({label: "Radial 90", value: m4m.framework.FillMethod.Radial_90});//
        FillMethodArr.push({label: "Radial 180", value: m4m.framework.FillMethod.Radial_180});//
        FillMethodArr.push({label: "Radial 360", value: m4m.framework.FillMethod.Radial_360});//
        //设置图片填充样式
        let fillMethodRefresh: Function = null;
        let setFillMethod = selection.addPropertyListener(image, "fillMethod", ValueType.number, (value) => {
            console.error("fillMethod", value);
            if (fillMethodRefresh) {
                fillMethodRefresh(value);
            }
        });
        //设置图片填充率
        let fillAmmountRefresh: Function = null;
        let setFillAmmount = selection.addPropertyListener(image, "fillAmmount", ValueType.number, (value) => {
            if (fillAmmountRefresh) {
                fillAmmountRefresh(value);
            }
        });

        let imageDefName: string = image.sprite.getName();
        let arr = ResMgr.GetAllABForType(AssetBundleFileType.Sprite);
        let selectArr = [];
        for (let i = 0; i < arr.length; i++) {
            let str = arr[i];
            selectArr.push({label: str, value: str});
        }
        return {
            enable: null,
            title: "Image2D",
            component: image,
            ticon: null,
            attrs: [

                //-------------------------------
                // {
                //     title: "Source Image",
                //     type: "selectList",
                //     attr: {
                //         options: selectArr,
                //         value: imageDefName,
                //         onChange: (val: string) => {
                //             let sprite = assetmgr.getDefaultSprite(val) as m4m.framework.sprite;
                //             if (!sprite) {
                //                 sprite = assetmgr.getAssetByName(val) as m4m.framework.sprite;
                //             }
                //             setSprite(sprite);
                //             image.transform.markDirty();
                //         },
                //         onClick: () => {
                //             // console.error("onClick1");
                //             if (onSelectFun) {
                //                 let arr = ResMgr.GetAllABForType(AssetBundleFileType.Sprite);
                //                 let selectArr = [];
                //                 for (let i = 0; i < arr.length; i++) {
                //                     let str=arr[i];
                //                     selectArr.push({ label: str, value: str });
                //                 }
                //                 onSelectFun(selectArr);
                //             }
                //         },
                //         onSetData: (selectFun) => {
                //             onSelectFun = selectFun;
                //         },
                //         setRefresh: (refresh) => {
                //             spriteRefresh = refresh;
                //         },
                //     }
                // },
                //--------------------------------------------

                {
                    title: "Source Image",
                    type: "asset",
                    attr: {
                        attrValue: image.sprite,
                        assetType: ["png", "jpg"],
                        onChange: (reference: AssetReference) => {

                            if (image.sprite == null || image.sprite["_ref"] == null || image.sprite["_ref"].guid != reference.key) {
                                //临时处理
                                let ea = EditorApplication.Instance;
                                ea.editorResources.loadTextureByKey(reference.key, (tex) => {
                                    tex["_ref"] = {...reference};

                                    var _sprite = new sprite();
                                    let ref: AtlasReference = tex["_ref"];
                                    _sprite["_ref"] = ref;
                                    _sprite.texture = tex;
                                    _sprite.border = new m4m.math.border(0, 0, 0, 0);
                                    _sprite.rect = new m4m.math.rect(0, 0, tex.glTexture.width, tex.glTexture.height);

                                    setSprite(_sprite);
                                    if (spriteRefresh) {
                                        spriteRefresh(_sprite);
                                    }
                                });
                                
                                //临时处理
                                // EditorApplication.Instance.editorResources.getSpriteReference({
                                //     key: reference.key,
                                //     guid: "",
                                // }, false, sp => {
                                //     if (sp) {
                                //         sp.use();
                                //     }
                                //     setSprite(sp);
                                //     if (spriteRefresh) {
                                //         spriteRefresh(sp);
                                //     }
                                // })
                            }
                        },
                        setRefresh: (refresh) => {
                            spriteRefresh = refresh;
                        },
                    }
                },

                //--------------------------------------------
                {
                    title: "Color",
                    type: "string",
                    attr: {
                        attrValue: {
                            value: image.color,
                        },
                        onChange: (value: {value: string}) => {
                            let arr = value.value.split(",");
                            // console.error(arr);
                            let r = parseFloat(arr[0]);
                            let g = parseFloat(arr[1]);
                            let b = parseFloat(arr[2]);
                            let a = parseFloat(arr[3]);
                            setColor(new m4m.math.color(r, g, b, a));
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            colorRefresh = refresh;
                        },
                    }
                },
                {
                    title: "Image Type",
                    type: "select",
                    attr: {
                        attrValue: {
                            defaultValue: image.imageType,
                        },
                        options: ImageTypeArr,
                        onChange: (value: { value}) => {
                            console.error(value.value);
                            //TODO 需要引擎相关人员查一下 设置平铺的功能有BUG 如果图片是个纯色的图片 会卡一段时间
                            setImageType(value.value);
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            imageTypeRefresh = refresh;
                        },
                    }
                },
                {
                    title: "Sliced ImageBorder l",
                    type: "number",
                    attr: {
                        attrValue: {
                            value: image.imageBorder.l,
                        },
                        onChange: (value: {value: number}) => {
                            image.imageBorder.l = value.value;
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            imageBorderRefreshL = refresh;
                        },
                    }
                },
                {
                    title: "Sliced ImageBorder t",
                    type: "number",
                    attr: {
                        attrValue: {
                            value: image.imageBorder.t,
                        },
                        onChange: (value: {value: number}) => {
                            image.imageBorder.t = value.value;
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            imageBorderRefreshT = refresh;
                        },
                    }
                },
                {
                    title: "Sliced ImageBorder r",
                    type: "number",
                    attr: {
                        attrValue: {
                            value: image.imageBorder.r,
                        },
                        onChange: (value: {value: number}) => {
                            image.imageBorder.r = value.value;
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            imageBorderRefreshR = refresh;
                        },
                    }
                },
                {
                    title: "Sliced ImageBorder b",
                    type: "number",
                    attr: {
                        attrValue: {
                            value: image.imageBorder.b,
                        },
                        onChange: (value: {value: number}) => {
                            image.imageBorder.b = value.value;
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            imageBorderRefreshB = refresh;
                        },
                    }
                },
                {
                    title: "Filled Fill Method",
                    type: "select",
                    attr: {
                        attrValue: {
                            defaultValue: image.fillMethod,
                        },
                        options: FillMethodArr,
                        // val: m4m.framework.FillMethod
                        onChange: (value: { value }) => {
                            console.error(value.value);
                            setFillMethod(value.value);
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            fillMethodRefresh = refresh;
                        },
                    }
                },
                {
                    title: "Filled Fill Amount",
                    type: "number",
                    attr: {
                        attrValue: {
                            value: image.fillAmmount,
                        },
                        onChange: (value: {value: number}) => {
                            setFillAmmount(value.value);
                            image.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            fillAmmountRefresh = refresh;
                        },
                    }
                }
            ]
        }
    }
}