import { cMap } from "../../CodeEditor/code/Map";

export class CmopFileManager{
    public static get Instance(): CmopFileManager {
        if (this._instance == null) {
            this._instance = new CmopFileManager();
        }

        return this._instance;
    }
    private static _instance: CmopFileManager;
    private compMap:cMap<any>=new cMap();
    public getACompByKey(key:string){
         return this.compMap.get(key);
    }
    public setACompByKey(key:string,comp:any){
        return this.compMap.set(key,comp);
   }
}