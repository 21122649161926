
// tslint:disable-next-line: class-name
export class number4 {
    public static classType = m4m["number4"] = number4;
    public v0: number = 0;
    public v1: number = 0;
    public v2: number = 0;
    public v3: number = 0;

}
