import {IEvent} from "./IEvent";
import {EventBinder} from "./EventBinder";
import {EventMap} from "./EventMap";

/**
 * 编辑器事件管理类, 编辑器中所有的事件(除了输入事件)都必须走该类来管理, 在 EventMap 中注册相应的事件
 */
export class EditorEventMgr implements IEvent<EventMap> {

    private bindMap = new Map<keyof EventMap, EventBinder<EventMap, keyof EventMap, EventMap[keyof EventMap]>[]>();

    public static get Instance() {
        return this._instance;
    }

    private static _instance: EditorEventMgr = new EditorEventMgr();
    
    private constructor() {
    }
    
    public addEventListener<K extends keyof EventMap, F extends EventMap[K]>(key: K, func: F): EventBinder<EventMap, K, F> {
        let binder: EventBinder<EventMap, K, F>;
        let list = this.bindMap.get(key);
        if (!list) {
            binder = new EventBinder(this, key, func);
            list = [binder];
            this.bindMap.set(key, list);
            return binder;
        }
        for (let i = 0; i < list.length; i++) {
            if (list[i].func == func) {
                return list[i] as EventBinder<EventMap, K, F>;
            }
        }
        binder = new EventBinder(this, key, func);
        list.push(binder);
        return binder;
    }
    
    public removeEventListener<K extends keyof EventMap, F extends EventMap[K]>(binder: EventBinder<EventMap, K, F>) {
        let list = this.bindMap.get(binder.key);
        if (list) {

            if (list.length > 0) {
                list.splice(list.indexOf(binder), 1);
            }

            if (list.length == 0) {
                this.bindMap.delete(binder.key);
            }
        }
    }
    
    public removeAllEventListener<K extends keyof EventMap>(key: K) {
        this.bindMap.delete(key);
    }
    
    public clearEventListener() {
        this.bindMap.clear();
    }
    
    public emitEvent<K extends keyof EventMap, F extends EventMap[K]>(key: K, cb: (callback: EventBinder<EventMap, K, F>["func"]) => void) {
        let list = this.bindMap.get(key);
        if (list) {
            let tempList = [...list];
            for (let i = 0; i < tempList.length; i++) {
                cb((tempList[i] as EventBinder<EventMap, K, F>).func);
            }
        }
    }
}