import { IComponentData } from "../../common/inspector/components/Component";
import { EditorApplication } from "../EditorApplication";
import { ValueType } from "../ValueType";

import I2DComponent = m4m.framework.I2DComponent;
export class ButtonAttributeDataMgr {
    //Button组件属性变化
    public static getButton2DData(node: I2DComponent): IComponentData {
        let selection = EditorApplication.Instance.selection;
        let button = node as m4m.framework.button;
        let selectArr = [];
        selectArr.push({ label: "None", value: m4m.framework.TransitionType.None });
        selectArr.push({ label: "Color Tint", value: m4m.framework.TransitionType.ColorTint });
        selectArr.push({ label: "Sprite Swap", value: m4m.framework.TransitionType.SpriteSwap });
        //设置按钮变换类型
        let transitionRefresh: Function = null;
        let setTransition = selection.addPropertyListener(button, "transition", ValueType.string, (value) => {
            if (transitionRefresh) {
                transitionRefresh(value);
            }
        });

        //设置默认显示图片
        let targetImageRefresh: Function = null;
        let setTargetImage = selection.addPropertyListener(button, "targetImage", ValueType.string, (value) => {
            if (targetImageRefresh) {
                targetImageRefresh(value);
            }
        });

        //设置按钮默认颜色
        let normalColorRefresh: Function = null;
        let setNormalColor = selection.addPropertyListener(button, "normalColor", ValueType.string, (value) => {
            if (normalColorRefresh) {
                normalColorRefresh(value);
            }
        });

        //按下按钮颜色
        let pressedColorRefresh: Function = null;
        let setPressedColor = selection.addPropertyListener(button, "pressedColor", ValueType.string, (value) => {
            if (pressedColorRefresh) {
                pressedColorRefresh(value);
            }
        });

        return {
            enable: null,
            title: "Button",
            component: button,
            ticon: null,
            attrs: [
                {
                    title: "Transition",
                    type: "select",
                    attr: {
                        attrValue:{
                            defaultValue: button.transition,
                        },
                        options: selectArr,
                        onChange: (value: { value: string | number }) => {
                            console.error(value.value);
                            // setTransition(val);
                            button.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            transitionRefresh = refresh;
                        },
                    }
                },
                {
                    title: "Target Graphic",
                    type: "string",
                    attr: {
                        attrValue: {
                            value: button.targetImage,
                        },
                        onChange: (value: {value: string}) => {
                            // setTargetImage(val);
                            button.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            targetImageRefresh = refresh;
                        },
                    }
                },
                {
                    title: "Normal Color",
                    type: "string",
                    attr: {
                        attrValue:{
                            value: button.normalColor,
                        },
                        onChange: (value: {value: string}) => {
                            let arr = value.value.split(",");
                            // console.error("button color :",arr);
                            let r = parseFloat(arr[0]);
                            let g = parseFloat(arr[1]);
                            let b = parseFloat(arr[2]);
                            let a = parseFloat(arr[3]);
                            setNormalColor(new m4m.math.color(r, g, b, a));
                            button.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            normalColorRefresh = refresh;
                        },
                    }
                },
                {
                    title: "Pressed Color",
                    type: "string",
                    attr: {
                        attrValue: {
                            value: button.pressedColor,
                        },
                        onChange: (value: {value: string}) => {
                            let arr = value.value.split(",");
                            // console.error(arr);
                            let r = parseFloat(arr[0]);
                            let g = parseFloat(arr[1]);
                            let b = parseFloat(arr[2]);
                            let a = parseFloat(arr[3]);
                            setPressedColor(new m4m.math.color(r, g, b, a));
                            button.transform.markDirty();
                        },
                        setRefresh: (refresh) => {
                            pressedColorRefresh = refresh;
                        },
                    }
                }
            ]
        }
    }
}