import { ElementEventBinder } from "./ElementEventBinder";
import { EditorInputMgr } from "./EditorInputMgr";
import { ElementInputMap } from "./ElementInputMap";

/**
 * 事件工厂, 统一添加监听事件, 统一移除
 */
export class ElementEventFactory {

    private eventList: ElementEventBinder<any, any, any>[] = [];

    /**
     * 返回事件绑定数量
     */
    public getEventSize() {
        return this.eventList.length;
    }

    /**
     * 添加监听事件
     */
    public addEventListener<K extends keyof ElementInputMap, F extends ElementInputMap[K]>(ele: HTMLElement, key: K, func: F): void {
        this.eventList.push(EditorInputMgr.Instance.addElementEventListener(ele, key, func));
    }

    /**
     * 移除所有监听事件
     */
    public removeAllEventListener() {
        for (let item of this.eventList) {
            item.removeListener();
        }
        this.eventList.length = 0;
        // console.log("event state: ", EditorInputMgr.Instance.getState());
    }
}