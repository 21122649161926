import {EditorApplication} from "../EditorApplication";
import {EditorEventMgr} from "../Event/EditorEventMgr";
import transform = m4m.framework.transform;
import PrimitiveType = m4m.framework.PrimitiveType;
import material = m4m.framework.material;
import vector4 = m4m.math.vector4;

/**
 * Hierarchy 面板操作函数
 */
export class EditorHierarchyHandle {
    /**
     * 创建空的3D物体
     */
    public static createEmpty(root: transform) {
        let trans = new transform();
        trans.name = "Game Object";
        root.addChild(trans);
        EditorEventMgr.Instance.emitEvent("SetActiveObject", cb => cb(trans));
        EditorEventMgr.Instance.emitEvent("CameraLookTransform", cb => cb(trans));
    }

    /**
     * 创建基础物体
     * @param type 物体类型
     * @param root 父节点
     */
    public static createPrimitive(type: PrimitiveType, root: transform) {
        let trans = m4m.framework.TransformUtil.CreatePrimitive(type, EditorApplication.Instance.engineApp);
        // let renderer: m4m.framework.meshRenderer = trans.gameObject.renderer as m4m.framework.meshRenderer;
        // let materials: material = renderer.materials[0];
        // materials.setShader(EditorApplication.Instance.engineApp.getAssetMgr().getShader("diffuse.shader.json"));
        // materials.setVector4("_MainColor", new vector4(1, 1, 1, 1));
        // materials.setFloat("_AlphaCut", 1);
        root.addChild(trans);
        EditorEventMgr.Instance.emitEvent("SetActiveObject", cb => cb(trans));
        EditorEventMgr.Instance.emitEvent("CameraLookTransform", cb => cb(trans));
    }
}