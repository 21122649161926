import React, { useEffect, useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { IAttrComponent } from '../Attribute';

export interface IRadioGroupAttrData extends IAttrComponent {
  onChange: (value: { value: string | number }) => void;
  setRefresh(func: Function): void;
  attrValue: {
    value: boolean;
    options: { label: string, value: string | number }[];
  }
}

/**
 * 单选框按钮组
 */
export function RadioGroupAttr(data: IRadioGroupAttrData) {

  const [value, setValue] = useState(data.attrValue.value);
  useEffect(() => {
    data.setRefresh((val) => {
      setValue(val)
    })
    setValue(value)
  }, [data]);

  return (
    <Radio.Group options={data.attrValue.options} onChange={(e) => {
      data.onChange({ value: e.target.value })
      setValue(e.target.value)
    }} value={value} optionType="button" />
  )
}
