
export enum ValueType {
    object,
    string,
    number,
    array,
    bool,
    vector2,
    vector3,
    vector4,
    rect,
    border,
    layout,
}