import React, { Component } from 'react'
import { engineDiv } from '../../index'
import { GridSnap } from './components/GridSnap'
import { ToolSetting } from './components/ToolSetting'
import ViewOptions from './components/ViewOptions'
import './index.css'

export class Scene extends Component {
  render(): React.ReactNode {
    return (
      <>
      {/* 屏蔽功能 */}
        {/* <div className="scene-head">
          <div className="select-menu">
            <ToolSetting />
            <GridSnap />
          </div>
          <div className="select-menu">
            <ViewOptions />
          </div>
        </div> */}
        <div
          ref={element => {
            element?.appendChild(engineDiv)
          }}
        ></div>
      </>
    )
  }
}
