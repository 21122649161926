import {EditorInputMgr} from "./EditorInputMgr";
import {IEventBinder} from "../Event/IEventBinder";

/**
 * 事件绑定对象
 */
export class ElementEventBinder<T, K extends keyof T, F extends T[K]> implements IEventBinder {

    /**
     * 绑定的函数
     */
    public funcMap: {[key: string]: { element: Element, func: Function } } = {};
    
    public constructor(public mgr: EditorInputMgr, public key: K, public callBack: F) {
    }

    /**
     * 移除当前事件监听
     */
    public removeListener() {
        this.mgr.removeElementEventListener(this as any);
    }
}