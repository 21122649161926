import { binTool } from "./binBuffer";

export class BufferDataReader{

    public static get Instance(): BufferDataReader {
        if (this._instance == null) {
            this._instance = new BufferDataReader();
        }

        return this._instance;
    }
    private static _instance: BufferDataReader;
    public readArrayBuffer(className:string, br: binTool){
        //   switch (className) {
        //   }
          return null;
    }
}